import React from 'react'
import { IonList, IonItem, IonRadioGroup, IonRadio, IonLabel, IonButton, isPlatform } from '@ionic/react'
import Loading from '../../components/spinner'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import { Title, SmallText, Spacer, Sectiontitle, NormalText } from '../../components/common'
import { connect } from 'react-redux'
import Basket from '../../lib/basket'
import { createOrder, setPaymentToken, showToast } from '../../store/actions'
import { forwardTo, insertIntoArray, goBack, isEmptyObject } from '../../lib/utils'
import { Capacitor } from '@capacitor/core'
import Stripe from '../../lib/stripe'
import { getConfig } from '../../appConfig'
import './index.css'

const { getMobile, setMobile } = Basket

class Checkout extends React.Component {
	constructor(props) {
		super(props)
		if (!getMobile() && this.props.profile && this.props.profile.mobile) {
			setMobile(this.props.profile.mobile)
		}
		this.state = {
			selectedCard: null,
			selectedJudopayCard: null,
			disableButton : false
		}
	}


	componentDidMount() {
		const { profile } = this.props
		if (getConfig().payment === 'judopay') {
			let judoPayCardsLen = profile.judo_pay_cards.length - 1
			this.changeSelectedPaymentCard(judoPayCardsLen)
		}
		if (profile.cardToken) {
			this.changeSelectedPaymentCard(profile.cardToken)
		}
	}

	componentDidUpdate(prevProps) {
		const { profile } = this.props
		let judoPayCardsLen = profile.judo_pay_cards.length - 1
		if (prevProps.profile.cardToken !== profile.cardToken) {
			this.changeSelectedPaymentCard(profile.cardToken)
		}

		if (getConfig().payment === 'judopay' && prevProps.profile.judo_pay_cards.length !== profile.judo_pay_cards.length) {
			this.changeSelectedPaymentCard(judoPayCardsLen)
		}
	}

	createOrderForPaymentType = paymentType => {
		if (getConfig().payment === 'judopay') {
			this.props.dispatch(setPaymentToken(this.props.cards[this.state.selectedCard].cardToken))
			this.props.dispatch(createOrder(paymentType))
		} else {
			//stripe
			this.props.dispatch(createOrder(paymentType))
		}
	}

	createOrder = paymentType => {
		this.setState({disableButton : true})
		if (!isEmptyObject(Basket.getDeliveryOption()) && Basket.getDeliveryOption().id === 'delivery') {
			const minOrder = Basket.getMinOrder()
			const total = Basket.getSubTotal()
			if (minOrder <= total) {
				this.createOrderForPaymentType(paymentType)
			} else {
				this.props.dispatch(showToast('Minimum order must be ' + Basket.formatPrice(minOrder), 'warning'))
			}
		} else {
			this.createOrderForPaymentType(paymentType)
		}
		setTimeout(() => {
      this.setState({ disableButton: false });
    }, 4000);
	}

	drawPayButton = () => {
		const { __ } = this.props
		let ret = null
		if (Stripe.getStripeInstance() && Capacitor.platform !== 'web') {
			if (!isPlatform('ios')) {
				//android
				ret = <IonButton expand="block" color="primary" onClick={ () => this.createOrder('google') }>{ __('Google Pay') }</IonButton>
			} else {
				//ios
				ret = <IonButton expand="block" color="primary" onClick={ () => this.createOrder('apple') }>{ __('Apple Pay') }</IonButton>
			}
		}
		return ret
	}

	changeSelectedPaymentCard = cardId => this.setState({ selectedCard: cardId }, () => {
		Basket.changeSelectedCard(cardId)
		// this.props.dispatch(updateProfile({ cardToken: cardId }, true)) // reason: this line has commented, because we're updating user profile on the BO
	})

	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.skipContactDetails) {
			forwardTo('/order', { skipBackToThePreviousPage: true })
		} else if (this.props.location.pathname === '/checkout' ) {
			forwardTo('/order', { skipBackToThePreviousPage: true })
		} else {
			goBack()
		}
	}



	render () {
		const { __, cards } = this.props
		return (
			<Loading transparent>
				<Layout color="transparent" headerTitle={ __('Checkout') } backHandler={this.backHandler} headerWithTitle={true}>
					<div className="flex-row-wrapper absolute-content">
						<Title className='web-only'>{ __('Checkout') }</Title>
						{/* <SmallText color="primary">{ __('Select Payment Card') }</SmallText> */}
						<SmallText color="primary" className="heading thiner-text">{ __('Confirm the payment method to use to complete') }</SmallText>
						<Spacer size={ 1 }/>
						<div className="scrollable-y">
							<IonList lines="full">
								{getConfig().payment === 'judopay' ?
									<IonRadioGroup onIonChange={ e => this.changeSelectedPaymentCard(e.detail.value) } value={ this.state.selectedCard }>
										{cards.map((card, id) => {
											const { cardLastFour, cardExpiry, cardType, name } = card
											const cardEx = insertIntoArray(cardExpiry, 2, '/')
											return (
												<IonItem key={ id }>
													<div tabIndex="-1"></div>
													<IonRadio
														slot="start"
														color="secondary"
														value={ id + '' }
														onIonSelect={() => {
															this.setState({ selectedCard: id }, () => {
																Basket.changeSelectedCard(id)
															})
														}}
													/>
													<IonLabel className="ion-text-wrap">
														<NormalText color="primary" className="single-item bold">{ name || '' }</NormalText>
														<Sectiontitle className="no-margin">•••• •••• •••• { cardLastFour }</Sectiontitle>
														<SmallText color="primary">{ __(cardType) } - { cardEx }</SmallText>
													</IonLabel>
												</IonItem>
											)
										})}
									</IonRadioGroup>
									:
									<IonRadioGroup onIonChange={ e => this.changeSelectedPaymentCard(e.detail.value) } value={ this.state.selectedCard }>
										{cards.map(card => {
											const { id, last4, brand, exp_month, exp_year, name } = card
											return (
												<IonItem key={ id }>
													<div tabIndex="-1"></div>
													<IonRadio
														slot="start"
														color="secondary"
														value={ id }
														onIonSelect={() => {
															this.setState({ selectedCard: id }, () => {
																Basket.changeSelectedCard(id)
															})
														}}
													/>
													<IonLabel className="ion-text-wrap">
														<NormalText color="primary" className="single-item bold">{ name }</NormalText>
														<Sectiontitle className="no-margin">•••• •••• ••••  { last4 }</Sectiontitle>
														<SmallText color="primary">{ __(brand) } - { exp_month }/{ exp_year }</SmallText>
													</IonLabel>
												</IonItem>
											)
										})}
									</IonRadioGroup>
								}
							</IonList>
							<IonButton fill="clear" color="secondary" className="link underlined" onClick={ () => forwardTo('/card-add', { addCardFromCheckout: true }) }>{ __('Add New Payment Card') }</IonButton>
						</div>
						<div className="flex-min">
							<IonButton color="secondary" disabled={ cards.length === 0 || this.disableButton } onClick={ this.createOrder } expand="block">{ __('Pay') } { Basket._getTotal() }</IonButton>
							{/* { this.drawPayButton() } */}
							{/* <div className="centered"><IonButton color="primary" fill="clear" className="link" onClick={ this.backHandler }>{ __('Cancel') }</IonButton></div> */}
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const mapStateToProps = store => {
	return {
		cards: store.orders.cards || [],
		profile: store.profile.profile || {}
	}
}

export default connect(mapStateToProps)(withTranslation(Checkout))
