import React from 'react';
import { IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonButton } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { withRouter } from 'react-router';
// import { arrowForward } from 'ionicons/icons'
import { SmallText, StrongText, Subtitle } from '../../components/common';
import NoDataSecond from '../../components/noDataSecond';
import Basket from '../../lib/basket';
import earnedArrow from '../../assets/images/earned-arrow.svg';
import redeemedArrow from '../../assets/images/redeemed-arrow.svg';
import { getConfig } from '../../appConfig';
import { forwardTo, isDefined } from '../../lib/utils';
import logoImage from '../../assets/images/logo-main.png'

const doRefresh = (event, getTransactionHistory) => {
  setTimeout(() => {
    event.detail.complete();
    getTransactionHistory();
  }, 1000);
};

const getDeliveryLabel = (deliveryLabel) => {
  switch (deliveryLabel) {
    case 'Scheduled Delivery':
      return 'Delivery Order';
    case 'Order for Delivery':
      return 'Delivery Order';
    case 'Click & Collect':
      return 'Collection Order';
    default:
      return deliveryLabel;
  }
};
const orderAgain = (past_orders, option) => {
  Basket.reset();
  Basket.setDeliveryOption(option);
  forwardTo(option.route, { selectedRestaurant: past_orders });
};
const HistoryTab = withRouter(
  ({ __, transactionHistory, getTransactionHistory, history, ...rest }) => {
    const type = rest.type || 'order';

    const handleRowClick = (item) => {
      if (type === 'order') {
        history.push('./history-details', { order: item });
      } else {
        // history.push('./history-details')
      }
    };
    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];

    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="history-content">
          {(transactionHistory || []).length === 0 ? (
            <NoDataSecond label="Your history will appear here" noDataImage = {logoImage}/>
          ) : (
            <IonGrid>
              {transactionHistory.map((i, index) => {
                const { stamp_power, transaction_date, location_name, label, item } = i;
                // const splitedLabel = label.split('#').splice(1).join('')
                const labelLocationName = label.split('#').splice(0).join('');
                let orderLabel = Basket.getOrderType(i.item);
                let option = (delivery || []).find((d) => d.id.includes(orderLabel));
                let status = item && item.status ? item.status : '';
                let orderId = i && i.item ? ' #' + i.item.id : '';
                return  (
                  <IonRow  key={index} className="box-wrapper history-item">
                    <IonCol size="6" onClick={() => handleRowClick(item)}>
                      {/* {type == 'order' &&
                        <div className="order-button-wrapper">
                          <div className="order-button bordered-primary bold">
                            {__(item && item.status ? item.status : '')}
                          </div>
                        </div>
                      } */}
                      <SmallText
                        color="primary"
                        className={`ellipsis block ${type === 'order' && 'order-location-name'
                          } history-small-text `}
                      >
                        {type === 'order' ? labelLocationName : location_name}
                      </SmallText>
                      <StrongText className='block roboto-slab'>
                        {type === 'order' ? (option ? getDeliveryLabel(option.label) : '') : __(label)}
                      </StrongText>
                      <SmallText color="primary" className="ellipsis block history-small-text">
                        {item
                          ? transaction_date
                          : Basket.getDate(transaction_date).format('DD MMMM YYYY [at] h:mm a')}
                      </SmallText>
                    </IonCol>
                    {type === 'order' ? (
                     <IonCol
                     style={{ alignSelf: 'center', textAlign: 'end' }}
                     className="order-button-col-width"
                   >
                     <IonButton color='secondary' onClick={() => orderAgain(item, option)}>
                       {__('Order Again')}
                     </IonButton>
                   </IonCol>
                    ) : (
                      <IonCol style={{ alignSelf: 'center',display:'flex',justifyContent:'flex-end' }}>
                  
                        <div className='transaction-points-wrapper'>
                            <Subtitle className={stamp_power < 0 ? 'bold danger-color' : 'bold secondary-color'}>
                              {stamp_power < 0 ? '-' : '+'}{Math.abs(stamp_power)}
                            </Subtitle>
                            <SmallText>{__('points')}</SmallText>
                          </div>
                      </IonCol>
                    )}
                  </IonRow>
                );
              })}
            </IonGrid>
          )}
        </div>
      </>
    );
  },
);

export default withTranslation(HistoryTab);
