import React from 'react';
import { withRouter } from 'react-router';
import {IonHeader,IonToolbar,IonButtons,IonButton,IonIcon,IonBadge,IonMenuToggle,} from '@ionic/react';
import { scan, basket, arrowBack } from 'ionicons/icons';
import { connect } from 'react-redux'
import Basket from '../../lib/basket';
import Icon from '../icon';
import { SmallText, Title } from '../common';
import { forwardTo, getRouteClassName, isWebConfig, goBack } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import './index.css';
import menuWhite from '../../assets/images/menu-white.svg';
import menuDark from '../../assets/images/menu-dark.svg';

const StaticHeader = ({ __, history, backHandler, title, headerWithTitle, showHamburgerIcon,hideBack }) => {
  const currentPath = history.location.pathname;
  const config = getConfig();
  const authPages = config.general.authRoutes.indexOf(currentPath) !== -1;
  const pagesWithBackButton = config.general.routesWithBackButton.indexOf(currentPath) !== -1;
  const hideStaticHeader = config.theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
  const routeClassName = getRouteClassName(currentPath);
  const routesWithDarkBg = config.general.routesWithDarkBg.indexOf(currentPath) !== -1;
  return (
    <>
      {hideStaticHeader ? null : config.theme.showHeaderOnAuthRoutes || !authPages ? (
        <IonHeader className={'static ' + routeClassName} color='primary'>
          <IonToolbar className={`${headerWithTitle ? 'no-logo' : ''} primary-toolbar`}>
            <IonButtons slot="start">
              {showHamburgerIcon ?

                <>
                  {!authPages && !isWebConfig() ?
                    <IonMenuToggle>
                      <img className="menu-button" src={routesWithDarkBg ? `${menuWhite}` : `${menuDark}`} alt=''></img>
                    </IonMenuToggle>
                    :
                    <>
                      <IonButton className="header-back-button" onClick={() => backHandler ? backHandler() : goBack()} >
                        <IonIcon onClick={() => backHandler ? backHandler() : goBack()} color={routesWithDarkBg ? 'white' : 'dark'} icon={arrowBack} className="icon" />
                      </IonButton></>
                  }
                </>
                :
                <>
                  {!authPages && !pagesWithBackButton ? (
                    !isWebConfig() ? (
                      <IonMenuToggle>
                        <img className="menu-button" src={routesWithDarkBg ? `${menuWhite}` : `${menuDark}`} alt=''></img>
                      </IonMenuToggle>
                    ) : null
                  ) : !hideBack && (
                    <IonButton className="header-back-button" onClick={() => backHandler ? backHandler() : goBack()} >
                      <IonIcon onClick={() => backHandler ? backHandler() : goBack()} color={routesWithDarkBg ? 'white' : 'dark'} icon={arrowBack} className="icon" />
                    </IonButton>
                  )}</>
              }

            </IonButtons>
            {!headerWithTitle ?
              <IonButtons color="primary">
                <IonButton
                  className="image-button"
                  onClick={() => forwardTo('/dashboard')}
                />
              </IonButtons>
              :
              <IonButton className='header-title' onClick={() => forwardTo('/dashboard')}>
                <Title >{title}</Title>

              </IonButton>
            }
            <IonButtons slot="end">
              {Basket.itemsCount() > 0 || isWebConfig() ? (
                <IonButton
                  disabled={Basket.itemsCount() > 0 ? false : true}
                  color="dark"
                  className="basket-button"
                  onClick={() => forwardTo('/order-summary')}
                  size={24}
                >
                  <div>
                    <div className="basket-icon-wrapper">
                      <IonBadge
                        slot="end"
                        color="primary"
                        className={Basket.itemsCount() >= 10 ? 'badge' : 'badge-small'}
                      >
                        {Basket.itemsCountAll()}
                      </IonBadge>
                      <Icon color={routesWithDarkBg ? 'white' : 'dark'} icon={basket} classname="icon" />
                    </div>
                    <div>
                      <SmallText color="gray">{Basket._getTotal()}</SmallText>
                    </div>
                  </div>
                </IonButton>
              ) : !authPages && !isWebConfig() ? (
                config.appType.hasLoyalty ? (
                  <IonButton
                    color="dark"
                    button
                    clear
                    onClick={() => forwardTo('/loyalty', { tab: 'scan' })}
                  >
                    <IonIcon color={routesWithDarkBg ? 'white' : 'dark'} slot="icon-only" icon={scan} />
                  </IonButton>
                ) : null
              ) : null}
            </IonButtons>
          </IonToolbar>
          {/* <IonToolbar color="transparent" /> */}
        </IonHeader>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { orders } = store;
  return {
    basketUpdated: orders.basketUpdated,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
