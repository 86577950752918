import { getEnv } from './lib/env.js'

let appConfig = {
	theme: {
		nav: {
			label: 'Cafe Brera'
		},
		showHeaderOnAuthRoutes: true,
		showInputIconsOnLogin: false,
		routesWithoutStaticHeader: [ '/item-details' ]
	},
	services: {
		google_maps: {
			google_api_key: getEnv('GOOGLE_API_KEY')
		}
		// stripe_key: 'pk_test_SfLlfP41LhHVIG2vrA95ZrFt' //Kole
	},
	api: {
		baseURL: getEnv('BASE_URL'),
		wordPressBaseURL: 'https://hybridapp.co.uk'
	},
	general: {
		clientName: 'Cafe Brera',
		appExitRoutes: [ '/', '/home', '/dashboard' ],
		authRoutes: [ '/login', '/register', '/reset-password' ],
		isReduxDevToolsOn: true,
		isWebPlatform: false,
		routesWithDarkBg: [],
		routesWithBackButton: [
			'/cards',
			'/card-add',
			'/allergen-information',
			'/order-summary',
			'/order-completed',
			'/apply-vouchers',
			'/terms',
			'/privacy',
			'/contact-details',
			'/click-and-collect',
			'/order-to-table',
			'/history-details',
			'/delivery',
			'/my-membership',
			'/membership-terms',
			'/allergens',
			'/apply-loyalty',
			'/order',
			'/create-account',
			'/checkout'],
	},
	appType: {
		hasOrdering: true,
		hasLoyalty: true,
		hasEmailValidationEnabled: true,
		hasCampaignManager: true,
		referAFriend: true,
		hasCancelOrder: false
	},
	delivery: [
		{
			id: 'collection',
			label: 'Click & Collect',
			route: '/click-and-collect'
		},
		{
			id: 'delivery',
			label: 'Order for Delivery',
			route: '/delivery'
		},
		{
			id: 'table',
			label: 'Order To Table',
			route: '/order-to-table'
		}
		// {
		// 	id: 'pick-up-point',
		// 	label: 'Order to Pick Up Point',
		// 	route: '/pick-up-point'
		// }
	],
	configS3: {
		imageNamePrefix: 'parish_wines_profile_image_',
		bucketName: 'hybrid-apps-profile-images',
		region: 'eu-west-1',
		accessKeyId: getEnv('S3_ACCESS_KEY_ID'),
		secretAccessKey: getEnv('S3_SECRET_ACCESS_KEY')
	},
	firebaseConfig: {
		apiKey: getEnv('FIREBASE_API_KEY'),
		authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
		databaseURL: getEnv('FIREBASE_DATABASE_URL'),
		projectId: getEnv('FIREBASE_PROJECT_ID'),
		storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
		messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
		appId: getEnv('FIREBASE_APP_ID'),
		measurementId: getEnv('FIREBASE_MEASUREMENT_ID')
	},
	payment: 'judopay',
	vouchersType: 1,
	menuDisplayType: 3
}

export const updateConfig = newConfig => appConfig = newConfig

export const getConfig = () => appConfig

export default appConfig
