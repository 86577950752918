import React from 'react';
import { withRouter } from 'react-router';
import {
  IonContent,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { getConfig } from '../../appConfig';
import Header from '../../components/header';
import { getRouteClassName, isWebConfig } from '../../lib/utils';
import './index.css';
import StaticHeader from '../staticHeader';
import { connect } from 'react-redux';
import logo from '../../assets/images/logo-main.svg'
import { Title } from '../common';
const defaultHeaderTitle = getConfig().general.clientName;

const Layout = ({
  history,
  children,
  headerTitle,
  hideSecondToolbar,
  hideBack,
  color,
  poster,
  blank,
  noPadding,
  contentClassName,
  scrollY,
  backHandler,
  profile,
  clientProfile,
  headerWithTitle,
  selectCategoryOnScroll,
  showHamburgerIcon
}) => {
  useIonViewWillEnter(() => {
    // eslint-disable-next-line no-console
    console.log('1. WillEnter event fired');
  });
  useIonViewDidEnter(() => {
    // eslint-disable-next-line no-console
    console.log('2. DidEnter event fired');
  });
  useIonViewWillLeave((a, b, c) => {
    // eslint-disable-next-line no-console
    console.log('3. WillLeave event fired', a, b, c);
  });
  useIonViewDidLeave((a, b, c) => {
    // eslint-disable-next-line no-console
    console.log('4. DidLeave event fired', a, b, c);
  });
  const routeClassName = getRouteClassName(history.location.pathname);
  return (
    <>
      {!profile.is_guest ? (

        <>
          {blank ? null : (
            <>
              {hideSecondToolbar ?
                <StaticHeader showHamburgerIcon={showHamburgerIcon} backHandler={backHandler} headerWithTitle={headerWithTitle} title={headerTitle || defaultHeaderTitle}
                />

                : (
                  <>
                    <StaticHeader
                      title={headerTitle || defaultHeaderTitle}
                      hideBack={hideBack}
                      backHandler={backHandler}
                      headerWithTitle={headerWithTitle}
                      showHamburgerIcon={showHamburgerIcon}
                    />
                    
                  </>
                )}
            </>

          )}


        </>
      ) : (
        <>
          {!isWebConfig() && (
            <IonToolbar
              color="transparent"
              className="primary-toolbar"
              style={{ backgroundImage: !isWebConfig() && !headerWithTitle ? `url(${logo})` : '', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}
            >
              {headerWithTitle && <Title className='centered'>{headerTitle}</Title>}
            </IonToolbar>
          )}
        </>
      )}
      <IonContent
        scrollY={scrollY !== undefined ? scrollY : true}
        color={color || 'white'}
        className={routeClassName + (contentClassName ? ' ' + contentClassName : '')}
      >
        {poster ? <div className={'poster ' + poster} /> : null}
        <div className={noPadding ? 'no-padding' : 'ion-padding'} onScroll={routeClassName === 'route-order' ? selectCategoryOnScroll : null}
        >{children}</div>
      </IonContent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    navConfig: state.common.navConfig,
    profile: state.profile.profile,
  };
}
export default connect(mapStateToProps)(withRouter(Layout));