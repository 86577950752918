import React from 'react'

import './index.css'

class Pill extends React.Component {
	render() {
		const { item, children, color, bgColor, className, headerTitle, paddingTop } = this.props
		const content = children ? children : typeof item === 'string' ? item : item.text
		const action = typeof item === 'string' ? null : item.action || null
		const actionAttr = action ? { onClick: action } : {}
		const style = {}
		style.color = color ? `var(--ion-color-${color})` : 'inherit'
		style.borderColor = headerTitle === 'Order Summary' ? 'var(--ion-color-secondary)' : 'var(--ion-color-primary)'
		style.paddingTop = paddingTop ? paddingTop : null
		if (bgColor) {
			style.backgroundColor = `var(--ion-color-${bgColor})`
		}
		return (
			<div className={'pill ellipsis' + (className ? ' ' + className : '') + (action ? ' pill-action' : '')} {...actionAttr} style={style}>
				{ content}
			</div>
		)
	}
}

export class PillGroup extends React.Component {
	render() {
		const { items, className, ...rest } = this.props
		return (
			<div className={'pill-group' + (className ? ' ' + className : '')}>
				{ items.map((item, index) => {
					return (
						<Pill key={'pill-' + index} item={item} {...rest} />
					)
				})}
			</div>
		)
	}
}

export default Pill
