import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import QRCode from 'qrcode-react';
import Basket from '../../lib/basket';
import moment from 'moment';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { getRewards, getTransactionHistory } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, isEmptyObject } from '../../lib/utils';
import UnlockRewardItems from '../../components/unlockRewardItems';

import {
  NormalText,
  SmallText,
  StrongText,
  Subtitle,
  BigLabel,
  Title,
} from '../../components/common';
import { IonCol, IonRow } from '@ionic/react';
import { getConfig } from '../../appConfig';
class Loyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      loyaltyType: ''
    };
  }

  componentDidMount() {
    this.props.dispatch(getRewards());
    this.props.dispatch(getTransactionHistory());
    const loyaltyType = getConfig().appType.hasUnlockRewardsOnLoyalty ? 'rewards' : 'points';
    this.setState({ loyaltyType })
    if (this.props.location && this.props.location.state && this.props.location.state.tab) {
      this.setState({ page: this.props.location.state.tab });
    }
  }
  getLabel(item) {
    let label = ''
    if (`${item.business_location_id}` == '-1') {
      label = 'Referral Bonus'
    }
    else if (`${item.business_location_id}` == '-2') {
      label = 'Sign up Bonus'
    }
    else if (`${item.business_location_id}` == '-3') {
      label = 'Refunded points'
    }
    else if (item.stamp_power < 0) {
      label = 'Redeemed Points'
    }
    else if (item.stamp_power > 0) {
      label = 'Earned Points'

    }
    return label
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.tab &&
      prevProps.location &&
      prevProps.location.state &&
      prevProps.location.state.tab &&
      prevProps.location.state.tab !== this.props.location.state.tab
    ) {
      this.setState({ page: this.props.location.state.tab });
    }
  }
  drawLoyaltyContent(profile, rewards, __, qr_code) {
    const { loyaltyType } = this.state;
    if (loyaltyType == 'points') {
      return (
        <div className="loyalty-content">
          <div className="content-box box-wrapper small-padding loyalty-content-wrapper">
            <Subtitle className='bold'>{__('Loyalty Balance')}</Subtitle>
            <div style={{ textAlign: 'end' }}>
              <Title className="no-margin lowercase points-balance-value">{profile.available_balance}</Title>
              <SmallText className='lowercase'>{__('Points')}</SmallText>
            </div>
          </div>
          <div className="loyalty-content-desc">
            <NormalText>{__('Collect points every time you scan.')}</NormalText>
            <NormalText className='block'>{__('Earn')} {getConfig().api_config.points_value_percentage} {__('points for every')} {Basket.getCurrency().label} {__('spend')}.</NormalText>
          </div>
        </div>
      )
    }
    else if (loyaltyType === 'rewards') {
      return (
        <div className="loyalty-content">
          <Subtitle className="bold">{__('Available rewards')}</Subtitle>
          <div className='loyalty-rewards-wrapper'>
            <UnlockRewardItems
              available_balance={profile.available_balance}
              rewards={rewards}
              qr_code={qr_code}
              __={__}
            />

          </div>

          {/* <div className='loyalty-rewards-wrapper'>
            {rewards.map(item => {
              return (
                <div className='loyalty-reward' style={{backgroundImage:`url(${item.reward.image})`}}>
                  {item.reward.cost>0 && <div className='loyalty-reward-points'><StrongText>{item.reward.cost} Points</StrongText></div>}
                  <div  className='loyalty-reward-name'><StrongText>{item.reward.name}</StrongText></div>
                </div>
              )
            })}
            </div> */}
        </div>
      )
    }
  }
  render() {
    const { __, rewards, profile, screenName, transactionHistory } = this.props;
    const pointsValuePercentage = getConfig().api_config ? getConfig().api_config.points_value_percentage : 0
	  const point = pointsValuePercentage > 1 ? ' points' : ' point'
    return (
      <Loading>
        <Layout
          color="transparent"
          headerTitle={__('Loyalty')}
          noPadding={true}
        >
          <div className='scrollable-y'>
            <div className="loyalty-header">
              <div className="loyalty-header-name">
                <Subtitle className="bold loyalty-name">
                  {screenName}
                </Subtitle>
                <SmallText color="primary" className="heading">{ __('Collect points every time you scan') }.</SmallText><br />
					      <SmallText color="primary" className="heading heading_bold">{ __('Earn ' + pointsValuePercentage + point + ' for every £1 spent') }.</SmallText>
              </div>
              <div className="loyalty-header-content">
                <div className="qr-code">
                  {isDefined(profile.qr_code) ? (
                    <div className="qr-holder">
                      <QRCode value={profile.qr_code} size={200} />
                    </div>
                  ) : (
                    <div>
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {this.drawLoyaltyContent(profile, rewards, __, profile.qr_code)}

            <div className="loyalty-history">
              <Subtitle className="bold ">{__('Latest Transactions')}</Subtitle>
              <div className="transactions-wrapper">
                {transactionHistory.map((el) => {
                  return (
                    <div className="content-box small-padding box-wrapper">
                      <IonRow>
                        <IonCol size="8" style={{ display: 'flex', justifyContent: 'center',flexDirection:'column' }}>
                          <SmallText >{el.location_name}</SmallText>
                          <Subtitle className='block bold transaction-label'>
                            {__(this.getLabel(el))}
                          </Subtitle>
                          <NormalText >
                            {Basket.getDate(el.transaction_date).format('DD MMM YYYY h:mm a')}
                          </NormalText>                        </IonCol>
                        <IonCol ize='4' style={{ display: 'flex', justifyContent: 'flex-end',alignItems:'center' }} >
                          <div className='transaction-points-wrapper'>
                            <Subtitle className={el.stamp_power < 0 ? 'bold danger-color' : 'bold secondary-color'}>
                              {el.stamp_power < 0 ? '-' : '+'}{Math.abs(el.stamp_power)}
                            </Subtitle>
                            <SmallText>{__('points')}</SmallText>
                          </div>

                        </IonCol>
                      </IonRow>
                      {/* <IonRow>
                        <IonCol>
                          
                        </IonCol>
                        <IonCol style={{ textAlign: 'end' }}>
                          <NormalText >
                            {' '}
                            {Basket.getDate(el.transaction_date).format('DD MMM YYYY h:mm a')}
                          </NormalText>
                        </IonCol>
                      </IonRow> */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { rewards } = state.restaurants;
  const { history } = state.orders;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name} ${profile.last_name}`;
  }
  return {
    profile,
    rewards: rewards || [],
    screenName,
    transactionHistory: history || [],
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Loyalty)));
